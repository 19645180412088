import components_plugin_KR1HBZs4kY from "/home/runner/work/website/website/.nuxt/components.plugin.mjs";
import unhead_4b0DrqpZDY from "/home/runner/work/website/website/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.11.18_eslint@8.33.0_typescript@4.9.4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_uAjPeUTBJT from "/home/runner/work/website/website/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.11.18_eslint@8.33.0_typescript@4.9.4/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_7xHJrUGHFj from "/home/runner/work/website/website/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.11.18_eslint@8.33.0_typescript@4.9.4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_yEhzsBSFep from "/home/runner/work/website/website/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.11.18_eslint@8.33.0_typescript@4.9.4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_RncFLZ5y4q from "/home/runner/work/website/website/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.11.18_eslint@8.33.0_typescript@4.9.4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_kxOvxrAQTW from "/home/runner/work/website/website/node_modules/.pnpm/nuxt@3.3.3_@types+node@18.11.18_eslint@8.33.0_typescript@4.9.4/node_modules/nuxt/dist/app/plugins/payload.client.js";
export default [
  components_plugin_KR1HBZs4kY,
  unhead_4b0DrqpZDY,
  vueuse_head_polyfill_uAjPeUTBJT,
  router_7xHJrUGHFj,
  prefetch_client_yEhzsBSFep,
  chunk_reload_client_RncFLZ5y4q,
  payload_client_kxOvxrAQTW
]