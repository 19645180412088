import { default as aboutvbJRa4GoOIMeta } from "/home/runner/work/website/website/pages/about.vue?macro=true";
import { default as _91_46_46_46slug_93FqwW88DZepMeta } from "/home/runner/work/website/website/pages/blog/[...slug].vue?macro=true";
import { default as indexbTyDd1mjxQMeta } from "/home/runner/work/website/website/pages/blog/index.vue?macro=true";
import { default as index8F11nDCyBrMeta } from "/home/runner/work/website/website/pages/index.vue?macro=true";
import { default as _91slug_93UmhWdeHqfcMeta } from "/home/runner/work/website/website/pages/series/[slug].vue?macro=true";
import { default as sheetsxI0B1YElOkMeta } from "/home/runner/work/website/website/pages/sheets.vue?macro=true";
export default [
  {
    name: aboutvbJRa4GoOIMeta?.name ?? "about",
    path: aboutvbJRa4GoOIMeta?.path ?? "/about",
    meta: aboutvbJRa4GoOIMeta || {},
    alias: aboutvbJRa4GoOIMeta?.alias || [],
    redirect: aboutvbJRa4GoOIMeta?.redirect || undefined,
    component: () => import("/home/runner/work/website/website/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93FqwW88DZepMeta?.name ?? "blog-slug",
    path: _91_46_46_46slug_93FqwW88DZepMeta?.path ?? "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93FqwW88DZepMeta || {},
    alias: _91_46_46_46slug_93FqwW88DZepMeta?.alias || [],
    redirect: _91_46_46_46slug_93FqwW88DZepMeta?.redirect || undefined,
    component: () => import("/home/runner/work/website/website/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexbTyDd1mjxQMeta?.name ?? "blog",
    path: indexbTyDd1mjxQMeta?.path ?? "/blog",
    meta: indexbTyDd1mjxQMeta || {},
    alias: indexbTyDd1mjxQMeta?.alias || [],
    redirect: indexbTyDd1mjxQMeta?.redirect || undefined,
    component: () => import("/home/runner/work/website/website/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: index8F11nDCyBrMeta?.name ?? "index",
    path: index8F11nDCyBrMeta?.path ?? "/",
    meta: index8F11nDCyBrMeta || {},
    alias: index8F11nDCyBrMeta?.alias || [],
    redirect: index8F11nDCyBrMeta?.redirect || undefined,
    component: () => import("/home/runner/work/website/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93UmhWdeHqfcMeta?.name ?? "series-slug",
    path: _91slug_93UmhWdeHqfcMeta?.path ?? "/series/:slug",
    meta: _91slug_93UmhWdeHqfcMeta || {},
    alias: _91slug_93UmhWdeHqfcMeta?.alias || [],
    redirect: _91slug_93UmhWdeHqfcMeta?.redirect || undefined,
    component: () => import("/home/runner/work/website/website/pages/series/[slug].vue").then(m => m.default || m)
  },
  {
    name: sheetsxI0B1YElOkMeta?.name ?? "sheets",
    path: sheetsxI0B1YElOkMeta?.path ?? "/sheets",
    meta: sheetsxI0B1YElOkMeta || {},
    alias: sheetsxI0B1YElOkMeta?.alias || [],
    redirect: sheetsxI0B1YElOkMeta?.redirect || undefined,
    component: () => import("/home/runner/work/website/website/pages/sheets.vue").then(m => m.default || m)
  }
]