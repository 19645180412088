export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"description","content":"Stay current in the evolving world of web development."},{"property":"og:description","content":"Stay current in the evolving world of web development."},{"property":"og:image","content":"https://cdn.davidmyers.dev/og/default.png"},{"property":"og:title","content":"David Myers | Dev"},{"property":"og:url","content":"https://davidmyers.dev"},{"name":"twitter:card","content":"summary_large_image"},{"name":"twitter:site","content":"@davidmyersdev"},{"name":"twitter:creator","content":"@davidmyersdev"}],"link":[{"href":"/logo-dark-20x20.png","rel":"icon","type":"image/png"},{"crossorigin":"","href":"https://fonts.gstatic.com","rel":"preconnect"}],"style":[],"script":[{"async":true,"defer":true,"src":"https://cdn.usefathom.com/script.js","data-site":"XUGBCQTL"}],"noscript":[],"title":"David Myers | Dev"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"